import clsx from "clsx"

type BlurbProps = {
  title: string
  body?: string
  centerBody?: boolean
  className?: string
  children?: React.ReactNode
}

export const Blurb = ({
  title,
  body,
  centerBody,
  className,
  children,
}: BlurbProps) => {
  const classes = clsx("mx-auto container-lg", className)
  const bodyClasses = clsx({
    ["mx-auto text-steel"]: true,
    ["text-center"]: centerBody,
    ["mb-3"]: children,
  })

  return (
    <div className={classes}>
      <h2 className="mb-2 text-center text-heading">{title}</h2>
      <p className={bodyClasses}>{body}</p>
      {children}
    </div>
  )
}

type BlurbHighlightProps = {
  icon: React.ReactElement
  title: string
  body?: string
}

type BlurbHighlightsContainerProps = {
  wideGap?: boolean
  inline?: boolean
  children?: React.ReactNode[]
}

export const BlurbHighlightsContainer = ({
  wideGap,
  inline,
  children,
}: BlurbHighlightsContainerProps) => {
  const classes = clsx(
    "flex md:flex-row justify-center items-center",
    wideGap ? "gap-2 md:gap-7" : "gap-2 md:gap-3",
    !inline && "flex-col",
  )

  return <div className={classes}>{children}</div>
}
export const BlurbHighlight = ({icon, title, body}: BlurbHighlightProps) => {
  return (
    <div className="flex flex-col items-center">
      {icon}
      <h4 className="mt-1 text-spacemist font-semibold text-subheading-2">
        {title}
      </h4>
      {!!body && <p className="mt-1">{body}</p>}
    </div>
  )
}
